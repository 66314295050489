import React from "react";
import "../Social Media/Social.css";



export const Social = () => {
  return (
    <div>
      <div className="msgff-heading">
        <span className="heading2">SOCIAL MEDIA</span>
      </div>
      {/* <div className="facebook">
        Facebook
        <div className="top">
          <div className="user"></div>
          <div className="media-block-container">
            <div className="left"></div>
            <div className="right"></div>
          </div>
        </div>
        <div className="bottom">
          <div className="user"></div>
          <div className="media-block-container">
            <div className="left"></div>
            <div className="right"></div>
          </div>
        </div>
      </div>

      <div className="twitter">
        Twitter
        <div className="top">
          <div className="user"></div>
          <div className="media-block-container">
            <div className="left"></div>
            <div className="right"></div>
          </div>
        </div>
        <div className="bottom">
          <div className="user"></div>
          <div className="media-block-container">
            <div className="left"></div>
            <div className="right"></div>
          </div>
        </div>
      </div> */}
    </div>
  );
};
